import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">

      <header className="App-header">
        <img className="Head-IMG" src="wtf.png" />
        <form>
          <span className="formfield"><label>
            Tag:
            <input type="text" name="tag" />
          </label>
          </span>
          <span className="formfield"><label>
            State:
            <input type="text" name="state" />
          </label>
          </span>
            <span className="formfield"><label>
            Attach:
            <input type="text" name="attach" />
          </label>
          </span>
          <br />
          <span className="formfieldblock">
              <span>Message/Comment:</span>
              <textarea  />
          </span>
          <span className="formcommandbar">
            <input type="submit" value="Tell them how you feel!" />
            <input type="submit" value="Never mind" />
          </span>
        </form>

      </header>
    </div>
  );
}

export default App;
